<template>
  <div class="store">
    <div class="ctn">
      <Release
        v-for="release in sortedReleases"
        :key="release.name"
        :release="release"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import Release from "./Release.vue";

export default defineComponent({
  components: {
    Release,
  },
  props: {
    releases: { type: Array, default: () => [] },
  },
  setup: (props) => {
    const sortedReleases = computed(() => {
      const copy = props.releases;
      return copy.sort((a, b) => {
        if (new Date(a.date) > new Date(b.date))  return -1
        if (new Date(a.date) < new Date(b.date)) return 1
        return 0
      });
    });

    return { sortedReleases };
  },
});
</script>

<style lang="scss" scoped>
.store {
  font-size: 1.3rem;
  margin-top: 20px;

  .ctn {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
  }
}
</style>
