<template>
  <div class="artist-detail-container">
    <h3>Artist</h3>
    <h1>{{ artist.name }}</h1>
    <img
      class="artist-img"
      :src="require(`../assets/artists/${artist.href}`)"
      :alt="artist.name"
    />
    <p v-html="artist.description"></p>
    <div class="releases-container">
      <CustomTitle text="Releases" />
      <div class="release-grid">
        <div
          v-for="release in artist.releases"
          :key="release.name"
          @click="showRelease(release)"
        >
          <img
            :src="require(`../assets/releases/${release.href}`)"
            alt="release"
          />
          <h5>{{ artist.name }}</h5>
          <h2>{{ release.name }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import store from "../store";

import CustomTitle from "./CustomTitle.vue";

export default defineComponent({
  components: { CustomTitle },
  setup: () => {
    const artist = computed(() => {
      return store.state.artistDetail.data;
    });

    const showRelease = (release) => {
      store.dispatch("SHOW_RELEASE", release);
    };

    return { artist, showRelease };
  },
});
</script>

<style scoped lang="scss">
.artist-detail-container {
  > h1 {
    margin-top: 5px;
    margin-bottom: 30px;
  }

  > p {
    margin: 30px 0;
    text-align: left;
    font-size: 18px;
  }
}

.artist-img {
  max-width: 100%;
  box-shadow: 0px 5px 10px rgb(186, 186, 186);
}

.release-grid {
  display: flex;
  flex-flow: row wrap;

  > div {
    box-sizing: border-box;
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    img {
      max-width: 100%;
      box-shadow: 2px 5px 15px rgb(128, 128, 128);
    }

    h5 {
      margin: 15px 0 0;
    }

    h2 {
      margin: 5px 0;
    }
  }
}
</style>
