<template>
  <header>
    <div class="desktop-nav">
      <div class="branding"><router-link to="/">MIZAR ALCOR</router-link></div>
      <ul class="nav-items">
        <router-link v-for="link in links" :key="link.view" :to="link.view">
          {{ link.label.toUpperCase() }}
        </router-link>
      </ul>
    </div>
    <div class="mobile-nav">
      <BurgerIcon class="burger-icon" @click="openMobileMenu" />
    </div>
    <transition name="mobileMenu" mode="out-in">
      <div class="mobile-menu" v-if="mobileMenuVisible">
        <ul class="nav-items">
          <router-link to="/" @click="hideMobileMenu">
            HOME
          </router-link>
          <router-link
            v-for="link in links"
            :key="link.view"
            :to="link.view"
            @click="hideMobileMenu"
          >
            {{ link.label.toUpperCase() }}
          </router-link>
        </ul>
        <CrossIcon class="cross-icon" @click="hideMobileMenu" />
      </div>
    </transition>
  </header>
</template>

<script>
import { defineComponent, ref } from "vue";
import BurgerIcon from "./BurgerIcon.vue";
import CrossIcon from "./CrossIcon.vue";

export default defineComponent({
  components: {
    BurgerIcon,
    CrossIcon,
  },
  setup: () => {
    const links = [
      {
        label: "Artists",
        view: "artists",
      },
      {
        label: "Releases",
        view: "releases",
      },
      {
        label: "About",
        view: "about",
      },
      {
        label: "Contact",
        view: "contact",
      },
    ];

    const mobileMenuVisible = ref(false);

    const openMobileMenu = () => {
      mobileMenuVisible.value = true;
    };

    const hideMobileMenu = () => {
      mobileMenuVisible.value = false;
    };

    return { hideMobileMenu, links, mobileMenuVisible, openMobileMenu };
  },
});
</script>

<style scoped lang="scss">
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  border-bottom: 1px solid black;
  padding: 0 20px;
  z-index: 2;
  background-image: linear-gradient(60deg, #29323c 0%, #485563 100%);

  .desktop-nav {
    display: flex;
    align-items: center;

    .branding a {
      font-size: 1.2rem;
      font-family: "Monoton";
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: none;
      }
    }

    .nav-items {
      display: flex;
      list-style-type: none;

      a {
        cursor: pointer;
        color: white;
        font-family: "Marcellus SC", serif;
      }
      a:not(:first-of-type) {
        margin-left: 15px;
      }
    }
  }

  .mobile-nav {
    display: none;
    text-align: initial;

    .burger-icon {
      height: 18px;
      padding: 1em 0;
      cursor: pointer;
    }
  }

  .mobile-menu {
    background: white;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    .nav-items {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;

      a {
        color: black;
        font-size: 35px;
        padding: 2rem 0;
      }
    }

    .cross-icon {
      position: absolute;
      top: 20px;
      right: 20px;
      width: 35px;
      cursor: pointer;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.mobileMenu-enter-active,
.mobileMenu-leave-active {
  transition: opacity 0.3s;
}

.mobileMenu-enter-from,
.mobileMenu-leave-to {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  header {
    .desktop-nav {
      display: none;
    }

    .mobile-nav {
      display: block;
    }
  }
}
</style>
