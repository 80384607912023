<template>
  <hr />
  <h3>{{ text }}</h3>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: { type: String, default: "" },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
hr {
  width: 100%;
  margin: 0;
  border: none;
  height: 1px;
  background: black;
}
h3 {
  text-transform: uppercase;
  text-align: left;
  border: 1px solid rgb(101, 101, 101);
  border-top-width: 0;
  width: fit-content;
  margin-bottom: 15px;
  margin-top: 0;
  border-bottom-right-radius: 10px;
  border-top: none;
  padding: 15px 10px;
  background: rgb(28, 177, 177);
  color: white;
  font-size: 0.9rem;
  font-family: "Marcellus SC", serif;
}
</style>
