<template>
  <div class="header">
    <ul class="social-ctn">
      <li>
        <a href="https://www.instagram.com/antipodes.music/" target="_blank">
          <img src="../assets/social/instagram.png" alt="instagram-logo" />
        </a>
      </li>
      <li>
        <a href="https://open.spotify.com/artist/2UR2Y93nOjRzUsgyDyQnMr?si=loNB1NJsRd26vjE9MLkwXA" target="_blank">
          <img src="../assets/social/spotify.svg" alt="spotify-logo" />
        </a>
      </li>
      <li>
        <a href="https://soundcloud.com/mizaralcor" target="_blank">
          <img src="../assets/social/soundcloud.png" alt="soundcloud-logo" />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/mizarproduction/" target="_blank">
          <img src="../assets/social/facebook.png" alt="facebook-logo" />
        </a>
      </li>
    </ul>
    <img class="ma-logo" src="../assets/ma-logo.png" alt="ma-logo" />
    <p :class="{ 'bold-message': messageIsBold }">
      {{ messageFormatted }}
    </p>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";

export default defineComponent({
  props: {
    message: { type: String, default: "" },
    messageIsBold: { type: Boolean, default: true },
  },
  setup: (props) => {
    const messageFormatted = computed(() => {
      return props.messageIsBold ? props.message.toUpperCase() : props.message;
    });

    return {
      messageFormatted,
    };
  },
});
</script>

<style lang="scss" scoped>
.header {
  padding-bottom: 20px;

  .social-ctn {
    display: flex;
    justify-content: flex-end;
    margin-right: 100px;
    margin-bottom: 0;
    list-style-type: none;

    img {
      height: 30px;
      margin-left: 2rem;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .ma-logo {
    height: 150px;
    width: 150px;
  }

  p {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .bold-message {
    font-weight: 700;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 768px) {
    .social-ctn {
      display: none;
    }

    .ma-logo {
      height: 100px;
      width: 100px;
    }
  }
}
</style>
