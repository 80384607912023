<template>
  <footer>
    <ul>
      <li>
        <a href="https://www.instagram.com/antipodes.music/" target="_blank">
          <img src="../assets/social/instagram.png" alt="instagram-logo" />
        </a>
      </li>
      <li>
        <a href="https://open.spotify.com/artist/2UR2Y93nOjRzUsgyDyQnMr?si=loNB1NJsRd26vjE9MLkwXA" target="_blank">
          <img src="../assets/social/spotify.svg" alt="spotify-logo" />
        </a>
      </li>
      <li>
        <a href="https://soundcloud.com/mizaralcor" target="_blank">
          <img src="../assets/social/soundcloud.png" alt="soundcloud-logo" />
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/mizarproduction/" target="_blank">
          <img src="../assets/social/facebook.png" alt="facebook-logo" />
        </a>
      </li>
    </ul>
    <p>
      <span>© {{ currentYear }} MIZAR ALCOR</span>
      <!-- <span class="creator">
        Website created by
        <a href="https://www.henri-delozanne.com/">Henri Delozanne</a>
      </span> -->
    </p>
  </footer>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  setup: () => {
    const currentYear = new Date().getFullYear();

    return { currentYear };
  },
});
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  justify-content: space-between;
  padding: 3rem 4rem;
  border-top: 1px solid rgb(222, 222, 222);
  max-height: 167px;
  background-image: linear-gradient(178deg, #f4f4f4 0%, #e6e6e6 100%);

  ul {
    display: flex;
    align-items: center;
    list-style-type: none;
    padding-inline-start: 0;

    img {
      height: 20px;
      margin-left: 2rem;
    }
  }

  p {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    span:first-of-type {
      margin-bottom: 5px;
    }

    a {
      text-decoration: none;
      color: black;
    }
  }

  .creator {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  footer {
    flex-direction: column-reverse;
    align-items: flex-end;
    padding: 10px;
    margin-left: -2.77778rem;
    margin-right: -2.77778rem;
  }
}
</style>
