<template>
  <div class="release-detail-container">
    <h3>{{ release.artists.join(", ") }}</h3>
    <h1>{{ release.name }}</h1>
    <img
      class="release-img"
      :src="require(`../assets/releases/${release.href}`)"
      :alt="release.name"
    />
    <p class="long-description" v-html="release.longDescription"></p>
    <iframe
      v-if="release.soundcloudUrl"
      class="player"
      width="100%"
      :height="release.playerHeight"
      scrolling="no"
      frameborder="no"
      allow="autoplay"
      :src="release.soundcloudUrl"
    ></iframe>
    <iframe
      v-else-if="release.bandcampUrl"
      style="border: 0; width: 100%"
      :height="`${release.playerHeight}px`"
      :src="release.bandcampUrl"
      seamless
    ></iframe>
    <p class="view-more" @click="showArtist(release.artists[0])">
      View more {{ release.artists[0] }} releases
    </p>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import store from "../store";
import data from "../data/data.json";

export default defineComponent({
  setup: () => {
    const release = computed(() => {
      return store.state.releaseDetail.data;
    });

    const showArtist = (artistName) => {
      const selectedArtist = data.artists.find(
        (artist) => artist.name === artistName
      );
      if (selectedArtist) store.dispatch("SHOW_ARTIST", selectedArtist);
    };

    return { showArtist, release };
  },
});
</script>

<style scoped lang="scss">
.release-detail-container {
  > h1 {
    margin-top: 5px;
    margin-bottom: 30px;
  }

  img {
    min-width: 100%;
  }

  .long-description {
    margin: 30px 0;
    font-size: 18px;
    text-align: justify;
    text-justify: inter-word;
  }

  .release-img {
    max-width: 100%;
  }

  .player {
    margin-top: 20px;
  }

  .view-more {
    color: black;
    cursor: pointer;
    transition: opacity 0.3s;
    font-size: 18px;

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>
