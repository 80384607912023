<template>
  <div class="fresh">
    <FreshRelease
      v-for="release in freshReleases"
      :key="release.name"
      :release="release"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import FreshRelease from "./FreshRelease.vue";

export default defineComponent({
  components: {
    FreshRelease,
  },
  props: {
    releases: { type: Array, default: () => [] },
  },
  setup: (props) => {
    const freshReleases = props.releases
      .filter((release) => release.priority)
      .sort((a, b) => a.priority - b.priority);

    return {
      freshReleases,
    };
  },
});
</script>

<style lang="scss" scoped>
.fresh {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

@media screen and (max-width: 1000px) {
  .fresh {
    flex-direction: column;
  }
}
</style>
