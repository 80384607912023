import { createStore } from "vuex";

const store = createStore({
  state: {
    drawerIsOpened: false,
    artistDetail: {
      show: false,
      data: undefined,
    },
    releaseDetail: {
      show: false,
      data: undefined,
      drawerColor: undefined,
    },
  },
  mutations: {
    closeDrawer(state) {
      state.drawerIsOpened = false;
    },
    loadArtist(state, artist) {
      state.artistDetail.data = artist;
    },
    loadRelease(state, release) {
      state.releaseDetail.data = release;
    },
    openDrawer(state) {
      if (!state.drawerIsOpened) state.drawerIsOpened = true;
    },
    resetArtist(state) {
      state.artistDetail.data = undefined;
    },
    resetRelease(state) {
      state.releaseDetail.data = undefined;
    },
    setDrawerColor(state, release) {
      state.releaseDetail.drawerColor = release.drawerColor;
    },
    showArtistDetail(state) {
      state.artistDetail.show = true;
    },
    showReleaseDetail(state) {
      state.releaseDetail.show = true;
    },
    hideArtistDetail(state) {
      state.artistDetail.show = false;
    },
    hideReleaseDetail(state) {
      state.releaseDetail.show = false;
    },
  },
  actions: {
    CLOSE_DRAWER({ commit }) {
      commit("closeDrawer");
      commit("resetArtist");
      commit("resetRelease");
    },
    SHOW_ARTIST({ commit }, artist) {
      commit("loadArtist", artist);
      commit("hideReleaseDetail");
      commit("showArtistDetail");
      commit("openDrawer");
    },
    SHOW_RELEASE({ commit }, release) {
      commit("loadRelease", release);
      commit("hideArtistDetail");
      commit("showReleaseDetail");
      commit("setDrawerColor", release);
      commit("openDrawer");
    },
  },
});

export default store;
