<template>
  <div class="wrapper">
    <div class="content">
      <Navbar id="nav" />

      <router-view v-slot="{ Component }" class="view">
        <transition name="fade" mode="out-in">
          <component :is="Component" :key="$route.path" />
        </transition>
      </router-view>

      <transition name="slide">
        <Drawer v-if="drawerIsOpened">
          <ArtistDetail v-if="artistDetail.show" />
          <ReleaseDetail v-if="releaseDetail.show" />
        </Drawer>
      </transition>
    </div>

    <Footer class="footer" />

    <transition name="overlayFade" mode="out-in">
      <div class="overlay" v-if="drawerIsOpened" @click="closeDrawer"></div>
    </transition>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import store from "./store";

import Navbar from "./components/Navbar.vue";
import Drawer from "./components/Drawer.vue";
import ArtistDetail from "./components/ArtistDetail.vue";
import ReleaseDetail from "./components/ReleaseDetail.vue";
import Footer from "./components/Footer.vue";

export default defineComponent({
  components: {
    Navbar,
    Drawer,
    Footer,
    ArtistDetail,
    ReleaseDetail,
  },
  setup: () => {
    const drawerIsOpened = computed(() => {
      return store.state.drawerIsOpened;
    });

    const artistDetail = computed(() => store.state.artistDetail);
    const releaseDetail = computed(() => store.state.releaseDetail);

    const closeDrawer = () => {
      store.dispatch("CLOSE_DRAWER");
    };

    return { artistDetail, closeDrawer, drawerIsOpened, releaseDetail };
  },
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Marcellus+SC&display=swap");
@import "./styles/columns.scss";
</style>

<style lang="scss">
html {
  background: no-repeat center /
    linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

#app {
  font-family: "DM Serif Display", serif;
  text-align: center;
}

.view {
  margin-top: 55px;
  padding-top: 20px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .content {
    flex: 1 0 auto;
  }

  .footer {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 768px) {
  .wrapper {
    padding-left: 2.77778rem;
    padding-right: 2.77778rem;
  }
}

#nav {
  a {
    text-decoration: none;
    transition: color 0.3s, opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }

    &.router-link-exact-active {
      color: #1cb1b1;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.2s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(100%);
  transition: all 150ms ease-in 0s;
}

.overlayFade-enter-active,
.overlayFade-leave-active {
  transition: opacity 0.2s ease;
}

.overlayFade-enter-from,
.overlayFade-leave-to {
  opacity: 0;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 2;
  cursor: pointer;
}
</style>
