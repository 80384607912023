<template>
  <div class="drawer">
    <CrossIcon class="cross-icon" @click="closeDrawer" />
    <slot></slot>
  </div>
</template>

<script>
import { defineComponent, computed } from "vue";
import store from "../store";

import CrossIcon from "./CrossIcon.vue";

export default defineComponent({
  components: {
    CrossIcon,
  },
  setup: () => {
    const closeDrawer = () => {
      store.dispatch("CLOSE_DRAWER");
    };

    const isReleaseDetail = computed(() => store.state.releaseDetail.show);

    const drawerColor = computed(() => {
      return store.state.releaseDetail.drawerColor || "white";
    });

    return { closeDrawer, drawerColor, isReleaseDetail };
  },
});
</script>

<style lang="scss" scoped>
.drawer {
  background: white;
  width: 40vw;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 11;
  overflow: hidden;
  overflow-y: scroll;
  padding: 1rem;
  box-sizing: border-box;
  background-image: linear-gradient(to top, #e6e9f0 0%, #eef1f5 100%);
  box-shadow: -5px 0px 40px rgba(0, 0, 0, 0.5);

  .cross-icon {
    width: 30px;
    position: absolute;
    top: 15px;
    right: 15px;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
      opacity: 0.7;
    }
  }
}

@media screen and (max-width: 768px) {
  .drawer {
    width: 90vw;
  }
}
</style>
