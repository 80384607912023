<template>
  <div>
    <Header :message="headerMessage" :messageIsBold="false" />
    <Fresh :releases="releases" />
    <hr />
    <h2 class="release-title">RELEASES</h2>
    <Store :releases="releases" class="releases" />
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import data from "../data/data.json";

import Header from "../components/Header.vue";
import Fresh from "../components/Fresh.vue";
import Store from "../components/Store.vue";

export default defineComponent({
  components: {
    Header,
    Fresh,
    Store,
  },
  setup: () => {
    const headerMessage =
      "Mizar Alcor is a cross-genres independent music label established in Paris";

    const releases = ref([]);

    data.artists.forEach((artist) => {
      releases.value.push(...artist.releases);
    });

    return { headerMessage, releases };
  },
});
</script>

<style scoped lang="scss">
.releases {
  margin-bottom: 40px;
}

.release-title {
  font-size: 26px;
}
</style>
