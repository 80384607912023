<template>
  <div class="release">
    <CustomTitle text="New release" />
    <div class="main" @click="showRelease">
      <div v-show="!imageLoaded" class="skeleton"></div>
      <img
        v-show="imageLoaded"
        :src="require(`../assets/releases/${release.href}`)"
        alt="release-artwork"
        @load="onImgLoad"
      />
      <div class="content">
        <h6 class="artist">{{ release.artists.join(", ") }}</h6>
        <h3 class="release-name">{{ release.name }}</h3>
        <p class="description">{{ release.shortDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from "vue";
import store from "../store";

import CustomTitle from "./CustomTitle.vue";

export default defineComponent({
  components: { CustomTitle },
  props: {
    release: { type: Object, default: () => {} },
  },
  setup: (props) => {
    const showRelease = () => {
      store.dispatch("SHOW_RELEASE", props.release);
    };

    const imageLoaded = ref(false);

    const onImgLoad = () => {
      imageLoaded.value = true;
    };

    return { imageLoaded, showRelease, onImgLoad };
  },
});
</script>

<style lang="scss" scoped>
.release {
  padding: 0 0 20px;
  margin: 0 20px;
  transition: opacity 0.3s;
  width: 50%;

  .main {
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      width: 40%;
      max-width: 500px;
      box-shadow: 2px 5px 15px rgb(128, 128, 128);
    }

    .skeleton {
      position: relative;
      width: 40%;
      height: 0;
      padding-bottom: 40%;
      background: #e9ecef no-repeat;
      animation: pulse 2s infinite;
      animation-delay: 0.5s;
    }

    @keyframes pulse {
      0% {
        opacity: 1;
      }

      50% {
        opacity: 0.4;
      }

      100% {
        opacity: 1;
      }
    }

    .content {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: justify;
      text-justify: inter-word;

      .artist {
        font-style: italic;
        font-size: 13px;
        margin: 0;
      }

      .release-name {
        font-weight: bold;
        font-size: 23px;
        margin: 20px 0;
      }

      @media screen and (max-width: 768px) {
        .artist {
          margin: 10px 0 0;
        }

        .release-name {
          margin: 5px 0 10px;
        }
      }

      .description {
        max-width: 400px;
        margin: 0;
        padding: 0 30px;
      }

      @media screen and (max-width: 768px) {
        .description {
          padding: 0;
        }
      }
    }

    @media screen and (max-width: 768px) {
      img {
        width: 100%;
      }

      .skeleton {
        width: 100%;
        padding-bottom: 100%;
      }

      .content {
        width: 100%;
      }
    }
  }

  &:hover {
    opacity: 0.7;
  }

  @media screen and (max-width: 1280px) {
    .main {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 768px) {
  .release {
    margin: 0;
  }
}

@media screen and (max-width: 1000px) {
  .release {
    width: 100%;
  }
}
</style>
